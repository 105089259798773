.show__item {
    color: #fff;
    background: linear-gradient(45deg,#182430, #203040);
    /*    
    height: 30rem;
    */
    height: auto;
    text-align: center;
    position: relative;
}

.lista-agenda .show__item {
    margin-bottom: 1rem;
}

.show__imagem__container {
    height: auto;
    width: 100%;
    display: block;
    overflow:hidden;
}
.show__imagem__container > figure {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: all .4s;
}

.show__item:hover figure {
    opacity: 1;
}


.show__item:hover .show__titulo, 
.show__item:hover .show__extrainfo, 
.show__item:hover .show__subtitulo  {
    transform: scale(1.1);
}


.show__data {
    position: absolute;
    display: block;
    top: 300px;
    right: 1rem;
    transform: translateY(-50%);
    background-image: url('../images/seloData.png');
    background-size: cover;
    background-position: center;
    text-align: center;
    z-index: 1;
    font-size: 1.3rem;
    padding: 1rem;	
}

.show__data > span {
    font-size: 42px !important;
}
.show__titulo {
    transition: 0.3s transform;
    text-transform: uppercase;
    margin-top: 2rem;
    font-size: 1.8rem;
    font-weight: bold;
}
.show__titulo {
    display: none;
}

.show__extrainfo {
    transition: 0.3s transform;
}

.show__subtitulo {
    font-size: 1.4rem;
    transition: 0.3s transform;
}

.show__link {
    position: absolute;
    bottom: 1rem;
    left: 1.5rem;
    font-size: 1.3rem;
    font-weight: bold;
    color: #fff;
}

.show__link:hover {
    color: #091016;
}

@media(max-width: 767px) {
    .show__titulo {
        font-size: 1.3rem;
        margin-top: 0;
    }

    .show__item {
        height: auto;
        padding-bottom: 1px;
    }

    .show__imagem__container {
        height: auto;
        margin-bottom: 15px;
    }
}