.galeria-interna {
	overflow-y: hidden;
}

.galeria-interna .foto {
	width: 100%;
	display: inline-block;
	position: relative;
	padding-top: 100%;
        margin-bottom: 20px
}

.foto-principal {
    border: 10px solid rgba(58, 58, 58, 0.92);
	box-shadow: 1px 1px 30px #333;
	margin-bottom: 2rem; 
	transition: all .3s;
}

.foto-principal:hover {
	transform: scale(1.03);
}

.icone-ver {
    background-color: rgba(0,0,0,0.1);
    border-radius: 100%;
    color: #fff;
    overflow: hidden;
    width: 6rem;
    height: 6rem;
    padding-top: 22px;
    -webkit-transition: all .3s;
    transition: all .3s;
    margin-top: -32px;
}

.icone-ver:hover {
	color: #fff;
	background-color: rgba(0,0,0,0.2);
	transform: scale(1.3);
}

.foto .div--compartilhar{
    position: absolute;
    bottom: 15px;
    left: 0;
    width: 100%;
}

.foto:hover .div--compartilhar{
    position: relative;
    bottom: 0;
}

a.botao--compartilhar {
    position: relative;
    display: inline-block;
    width: 140px;
    color: #fff;
    background-color: #142d3c;
}

a.botao--compartilhar:hover {
	background-color: #0a161d;
}


.foto:hover .figura__legenda {
	top: inherit;
	height: auto;
	padding: 15px 15px;
}