.section-videos {
	background: radial-gradient(#182430, #091016);
}
.video-item {
    cursor: pointer;
    position: relative;
    opacity: 0.5;
    margin-bottom: 2rem;	
    transition: .3s all;
}

.video-item:after { 
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 30;
}
.video-item:hover {
	transform: scale(1.03);
	opacity: 1;
}
.video-item .figura__legenda {
	padding-top: 25%;
	font-size: 2.5rem;
}

.modal-video {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 1000;
    background-color: rgba(0,0,0,.7);

}

.video-container {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 75%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: 10px solid #fff;
    box-shadow: 1px 1px 115px #393754;
}
.container-video {
    position: relative;
}
.video-container .loading-video {
    color: #FF5722;
    font-size: 6rem;
    left: 50%;
    margin-left: -30px;
    margin-top: -30px;
    position: absolute;
    top: 50%;
}

.fecha-video {
    position: absolute;
    right: -36px;
    top: -36px;
    background-color: #fff;
    padding: 1rem 1.1rem;
    border-radius: 100%;
    transition: transform .5s;
    cursor: pointer;
}
.fecha-video:hover {
    transform: rotate(360deg) scale(1.2);
}