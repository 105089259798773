.table {
	color: #fff;
}
.table> tbody {
	text-transform: uppercase;
}
.table> tbody > tr {
	background-color: transparent;
}

.table>tbody>tr>td {
	border-top: none;
}
.table> tbody > tr:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.42);
}

.tabela-cc tr > td:nth-child(even),
.tabela-cc tr > th:nth-child(even) {
	text-align: right;
}

.titulo-sorteio {
	color : #fff;
	font-weight: bold;
}

.titulo-inscricao {
	color : orange;
	font-weight: bold;
	margin-bottom: 1rem;
}

.proximo-sorteio {
    color: #fff;
    text-align: center;
    margin-bottom: 3rem;
    font-size: 1.8rem;
    background-color: #152431;
    padding: 2rem;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.hora-sorteio {
	margin-top: 1.5rem;
	display: block;
	font-size: 3rem;
	line-height: 3.2rem;
}

.link-regulamento {
	color: orange;
	font-weight: bold;
}

.link-regulamento:hover {
	color: orange;
	text-decoration: underline;
}

.regulamentoAceitoLabel {
	color:#fff; 
	padding-top: 8px;
}
.regulamentoAceitoLabel > input {
    transform: scale(1.3) translateY(2px);
    margin-left: 11px;
}


.modal-sorteio {
	background-color: #091016;
} 
.modal-sorteio .close {
    background-color: #fff;
    padding: 6px 13px;
}

.modal-sorteio .modal-title {
	color: orange;
}
.modal-sorteio .modal-footer,
.modal-sorteio .modal-header {
	border-color: #030406;
}

@media(max-width: 767px) {
	.titulo-inscricao {
		text-transform: uppercase;
		text-align: center;
	}
}