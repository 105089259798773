.footer-container {
    background: #464e59;
    color: #fff;
}

.footer_titulo {
	font-size: 2rem;
	color: #fff;
	margin-top: 0;
	text-align: right;
}

.footer_titulo + img {
	float: right;
}

.footer__links {
	list-style: none;
}

.footer__links > li {
	color: #fff;
	font-size: 1.3rem;
	line-height: 2rem;
}

.footer__links > li > a {
	color: inherit;
	text-transform: uppercase;
}


.horario-atendimento {
    text-align: right;
    color: #fff;
}

.horario-atendimento__lead {
    font-size: 16px;
    display: inline-block;
    font-weight: bold;
    margin-bottom: 12px;
}
 

.horario-atendimento__normal {
    font-size: 14px;	
}

.estranho {
	padding: 1.5rem;
	box-sizing: border-box;
	font-size: 1.5rem;
	text-align: center;
	display: block;
	max-width: 90%;
	margin: 0 auto;
	border: 4px solid #656766;
}

.estranho2 {
	text-align: center;
	font-size: 2rem;
	margin: 1.5rem 0;
}

@media(max-width: 767px) {
	.footer_titulo {
		text-align: center;
	}

	.footer_titulo + img {
		margin: 0 auto;
		float: none;
	}
}