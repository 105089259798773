/* ==========================================================================
   Author's custom styles
   ========================================================================== */

a {
    color: #333;
}

a:hover {
    text-decoration: none;
    color: #444;
}
/* GLOBAL */
ul { 
    padding-left: 0;
}

body {  
    padding-bottom: 0px;
    overflow-x: hidden;
    font-size: 14px;
    line-height: 21px;
}

body.body_pdf{
    background: #fff !important;
    /* background: #bbc0c5 !important; */
}

.cl-white{
    color: #fff; 
}

.dropdownToolbarButton > select > option, body.body_pdf #secondaryToolbar, body.body_pdf #mainContainer .findbar, body.body_pdf #sidebarContainer > div, body.body_pdf #toolbarViewer {
    background: #204063 !important;
}
.doorHanger::after, .doorHangerRight::after {
    border-bottom-color: #204063 !important;
}
.navbar-toggle {
    margin-top: 16px;
    margin-right: 0px;
}
.navbar-toggle .icon-bar {
    display: block;
    width: 48px;
    height: 7px;
    border-radius: 35px;
    background-color: #fff;
    margin-bottom: 8px;
}

.input-flat {
    outline: none;
    border: none;
    padding: 8px 20px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 28px;
    -webkit-transition: all .4s ease-out;
    -moz-transition: all .4s ease-out;
    -o-transition: all .4s ease-out;
    transition: all .4s ease-out;   

    border: 2px solid transparent;
}

.input-flat:focus {
    border: 2px solid #233E69;
}

.shadow{
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.55);
    -moz-box-shadow:    0px 2px 5px 0px rgba(0, 0, 0, 0.55);
    box-shadow:         0px 2px 5px 0px rgba(0, 0, 0, 0.55);
}


.borda-luminosa {
    border-width: 4px;
    animation: borda-luminosa 1.5s ease-in-out infinite;
}

@keyframes borda-luminosa {
    0% { 

    }
    50% { 
        -webkit-box-shadow: 0px 0px 10px 3px #9E9E9E;
        -moz-box-shadow: 0px 0px 10px 3px #9E9E9E;
        box-shadow: 0px 0px 10px 3px #9E9E9E;
    }
    100% { 

    }
}

.gradiente {
    background: linear-gradient(180deg,rgba(0,0,0,.06) 0,transparent);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0f000000",endColorstr="#00000000",GradientType=0);	
}


.fundo--branco {
    background-color: #fff;
}

.fundo--vermelho {
    background-color: red;
}

.fundo--preto {
    background-color: #333;
}

.fundo--gradiente {
    background-image: url(../images/gradiente.jpg);
    background-size: contain;
    background-repeat: repeat-x;
}


.bg-body-img {
    background-position: center;
    background-color: transparent;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.container-video {
    width: 100%;
}

.conteudo-pagina {
    text-align: left;
}

.titulo-interno {
    background-image: url('../images/bgBaseBlack.jpg');
    background-position: center;
    background-size: cover;
    padding: 26px 0;
    margin-bottom: 30px;

}

.titulo-interno__titulo {
    color: gray;
    font-size: 36px;
    display: inline-block;
    font-weight: bold;
    margin-bottom: 3rem;
}

.titulo-interno__icone {
    display: inline-block;
    height: 43px;
    width: 43px;
    background-image: url('../images/iconMaisFacil.png');
    background-position: center;
    background-size: contain;	
    margin: 0 26px 0 86px;
    margin-bottom: -8px;
}

.secao-interna {
    margin-bottom: 40px;
}

/*Header*/
.menu-topo {
    background-image: url('../images/bgTopo.png');
    background-position: bottom;
    background-size: cover;
    background-color: transparent;
    background-repeat: no-repeat;
}

.fundo-logo {
    background-image: url(../images/bgLogo.png);
    background-size: cover;
    background-position: bottom;
    margin-top: -30px;
    max-width: 100%;
}


.fundo-logo > img {
    transform: scale(0.7);
}


.menu-topo__nav > li.active,
.menu-topo a.menu-topo__nav__link:hover,
.menu-topo a.menu-topo__nav__link:focus {
    background-color: transparent;
}

/*HOVER DROPDOWN FICA TRANSPARENTE*/
.menu-topo__nav > li.dropdown,
.menu-topo__nav li.dropdown > a.menu-topo__nav__link:hover,
.menu-topo__nav li.dropdown > a.menu-topo__nav__link:focus {
    /*background-color: transparent;*/
}


.menu-topo a.menu-topo__nav__link {
    position: relative;
    padding: 30px 30px;
    color: #fff !important;
    font-size: 18px;
    font-weight: bold;
    border-left: none;
    -webkit-transition: background-color .4s ease-out;
    -moz-transition: background-color .4s ease-out;
    -o-transition: background-color .4s ease-out;
    transition: background-color .4s ease-out;    

}


.menu-topo a.menu-topo__nav__link {
    position: relative;
    padding: 30px 30px;
    color: #fff !important;
    font-size: 18px;
    text-transform: uppercase;
    -webkit-transition: background-color .4s ease-out;
    -moz-transition: background-color .4s ease-out;
    -o-transition: background-color .4s ease-out;
    transition: background-color .4s ease-out;    

}



.menu-topo a.menu-topo__nav__link::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 21px;
    left: 0;
    height: 3px;
    background-color: transparent;
    width: 90%;
    transform: translateX(-50%);
    margin-left: 50%;

    -webkit-transition: background-color .4s ease-out;
    -moz-transition: background-color .4s ease-out;
    -o-transition: background-color .4s ease-out;
    transition: background-color .4s ease-out;        

}

.menu-topo a.menu-topo__nav__link:hover::before {
    background-color: #fff;
}

@media (max-width: 1282px) {
    .menu-topo a.menu-topo__nav__link {
        padding: 30px 6px;
        font-size: 16px;
    }

    .menu-topo a.menu-topo__nav__link::before {
        width: 100%;
    }
}
/*
.menu-topo a.menu-topo__nav__link::after {
    content: "";
    display: block;
    position: absolute;
    top: 10px;
    right: 0;
    height: 50px;
    background-color: #fff;
    width: 2px;
}*/

.menu-topo__nav li:last-child a::after {
    display: none;
}

.menu-topo .navbar-nav>li>.dropdown-menu {
    border-radius: 8px;
    left: 50%;
    transform: translateX(-50%);
    right: initial;
    border: 2px solid #73181b;
    margin-top: -10px;
}


.menu-topo .navbar-nav>li>.dropdown-menu:after, .menu-topo .navbar-nav>li>.dropdown-menu:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.menu-topo .navbar-nav>li>.dropdown-menu:after {
    border-color: rgba(146, 30, 34, 0);
    border-bottom-color: #921e22;
    border-width: 12px;
    margin-left: -12px;
}
.menu-topo .navbar-nav>li>.dropdown-menu:before {
    border-color: rgba(115, 24, 27, 0);
    border-bottom-color: #73181b;
    border-width: 14px;
    margin-left: -14px;
}




.menu-topo__dropdown-container {
    background-color: #921e22;
}

ul > li.menu-topo__dropdown-item {

}

ul.menu-topo__nav > .dropdown li:last-child {
    /* border-color: transparent; */
}
ul > li.menu-topo__dropdown-item> a {
    padding: 10px;
}

ul > li.menu-topo__dropdown-item> a:hover {
    background-color: #73181b;
    color: #fff;
}


li > a.menu-topo__dropdown-link {
    color: #fff;	

}


li > a.menu-topo__dropdown-link {
    color: #fff;	
}

.menu-topo .dropdown-menu .divider {
    max-width: 80%;
    margin: 9px auto;
}

.menu-unidade {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 0;
    z-index: 1;
    background-image: url(../images/bgSeletorCidade.png);
    border: none;
    width: 214px;
    font-weight: bold;
    background-color: transparent;
    color: #fff;
    font-size: 14px;
    display: inline-block;
    height: 26px;
    outline: none;
    padding-left: 34px;
    list-style: none;
}

.menu-unidade__item {
    background-color: #fff;	
    color: #333;
    font-size: 13px;
}


.titulo-pagina {
    background-image: url('../images/bgTitle.jpg');
    background-size: cover;
    padding: 60px 0 20px 0;
    text-align: right;
    color: #fff;
    margin-top: -40px;
}

.titulo-galeria {
    padding: 20px 0;
    text-align: left;
    background-color: transparent;
    color: #333;
}

.titulo-galeria h2 {
    color: #fff;
}

.titulo-pagina a {
    color: #fff;
}

.titulo-pagina a:hover {
    color: #ccc;
}

.titulo-pagina__titulo {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 0;
}

.titulo-pagina__subtitulo {
    margin-top: 0;
}


.corpo-padding {
    padding: 30px 0 40px 0;
    background-color: transparent;
}

.bg-computador {
    background-image: url('../images/bgCursos.jpg');
    background-size: cover;
}

.cartao-item__container {
    padding: 0 20px;
}

.cartao-item {
    cursor: pointer;
    margin-bottom: 16px;
    border: 2px solid #990100;
    padding: 6px;
}

.cartao-item--branco {
    background-color: #fff;
}
.cartao-item__imagem-container {
    padding:0;
}
.cartao-item__imagem {
    background-image: url('http://placehold.it/300x300');
    background-size: cover;
    background-position: center;
    padding-top: 100%;
}

.cartao-item__conteudo {
    padding-top: 10px;
}

.cartao-item__titulo {
    margin-top: 0;
    font-weight: bold;
    color: #333;
    text-transform: uppercase;
}

.cartao-item__botao {
    border-radius: 100%;
    background-color: #810a10;
    color: #fff;
    font-size: 20px;
    display: inline-block;
    padding: 8px 13px;
    position: absolute;
    bottom: 26px;
    right: 14px;
    border: 2px solid transparent;

    -webkit-transition: all .4s ease-out;
    -moz-transition: all .4s ease-out;
    -o-transition: all .4s ease-out;
    transition: all .4s ease-out;  	    
}

@media (max-width: 412px) {
    .cartao-item__botao {
        bottom: 92px;
    }
}


.cartao-item:hover .cartao-item__botao,
.cartao-item__botao:hover {
    color: #fff;
    transform: rotateZ(360deg);
    border: 2px solid #810a10;
    background-color: #fff;
    color: #810a10;
    text-decoration: none;

}

.crop-links-home {
    overflow: hidden;
    border-bottom-left-radius: 10% 30%;
    border-top-left-radius: 10% 30%;
    border-top-right-radius: 10% 30%;
}


@media (max-width: 771px) {
    .crop-links-home { overflow: visible; }
}

.links-home {
    display: block;
    margin: 0 auto;
    list-style-type: none;
}

.links-home__item {
    height: 200px;
    display: block;
    background-color: #5e93c9;
    border: 2px solid #fff;
    padding: 40px;
    text-align: center;
    color: #0d2f52;
    position: relative;
    overflow: hidden;

    -webkit-transition: all .4s ease-out;
    -moz-transition: all .4s ease-out;
    -o-transition: all .4s ease-out;
    transition: all .4s ease-out;  	

}

.links-home__item--primario {
    background-color: #233e69;
    color: #fff;
}

.links-home__item--primario:hover {
    color: #fff;	
}



.links-home__item__icone {
    font-size: 62px;
    display: block;
    margin-bottom: 10px;

    -webkit-transition: all .4s ease-out;
    -moz-transition: all .4s ease-out;
    -o-transition: all .4s ease-out;
    transition: all .4s ease-out;   	
}


.links-home__item:hover .links-home__item__icone {
    transform: scale(1.3);
}

.links-home__item:hover .links-home__item__legenda {
    margin-top: 10px;
}


.links-home__item__legenda {
    font-size: 18px;
    font-weight: bold;
    display: inline-block;
    -webkit-transition: all .4s ease-out;
    -moz-transition: all .4s ease-out;
    -o-transition: all .4s ease-out;
    transition: all .4s ease-out;   		
}

.links-home__item__btn {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 10px 20px;
    color: #fff;
    background-color: #326292;

    -webkit-transition: all .4s ease-out;
    -moz-transition: all .4s ease-out;
    -o-transition: all .4s ease-out;
    transition: all .4s ease-out;   		
}

.links-home__item--primario .links-home__item__btn {
    background-color: #00053c;	
}


.noticia-container {
    color: #fff;
}

.noticia__imagem {
    max-width: 50%;
    float: left;
    margin: 0 30px 10px 0;
    border: 10px solid #333;
}
.conheca-o-clube .noticia__imagem {
    max-width: 100%;
}

@media only screen and (max-width: 424px) {
    .noticia__imagem {
        max-width: 100%;
    }
}


.galeria__itens {
    margin: 30px 0;
}
.galeria__item {
    padding-top: 100%;
    background-color: red;
    display: block;
    margin-bottom: 30px;

    background-size: cover;
    background-position: center;
}

/*Rodape*/
img.logo-img {
    max-height: 110px;
}
.rodape {
    background-image: url('../images/bgBaseRed.jpg');
    background-attachment: fixed;
    background-size: cover;
    padding: 20px 0;
}



.midia-social {

}


.contato__item {
    text-align: center;
}
.contato__item__texto,
a.contato__item__texto {
    font-size: 22px;
    line-height: 26px;
    font-weight: bold;
    display: block;
    color: #fff;
}

.contato__item__icone {
    display:inline-block;
    width: 40px;
    height: 44px;
    background-size: cover;
    margin-right: 10px;


}

.contato__item__icone.local {
    background-image: url('../images/iconLocate.png');
    padding-left: 44px;
}

.contato__item__icone.telefone {
    background-image: url('../images/iconPhone.png');
    padding-left: 44px;
}

.contato__item__icone.email {
    background-image: url('../images/iconMail.png');
    padding-left: 44px;
}

/* BANNER */
.banner-topo {
    background-color: #333;
    margin-top: -26px;
    position: relative;
}

.banner-topo__itens {
    overflow: hidden;
}

.banner-topo__item {
    /* height: 500px;*/
    padding-top: 40%;
    width: 100%;
    cursor: pointer;

    background-size: cover;
    background-position: center;
} 

.banner-topo-botao{
    padding: 12px 0;
    transform: translateY(-100%);
    z-index: 100;
}

@media only screen and (max-width: 758px) {
    .banner-topo {

    }
    .banner-topo__item {
        padding-top: 50%;
    }
    .banner-topo-botao{
        transform: translateY(0);
        margin-top: 20px
    }
}

.owl-dot.active {
    z-index: 1001;
}


.banner-topo__indicadores {
    margin-top: 0px !important;
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: #333;   
    padding: 10px 20%;     
}

.banner-topo__indicadores .owl-dot > span,
.banner-topo__indicadores .owl-dot.active span {
    width: 18px !important;
    height: 22px !important;
    background-color: #fff;
}

.banner-topo__indicadores .owl-dot.active > span {
    background-color: #d60005;
    border-color: #d60005;
}

.owl-theme .owl-nav [class*='owl-'] {
    position: absolute;
    top: 40vh;
    background-color: rgba(51, 51, 51, 0.43137254901960786);
    border-radius: 100%;
    font-size: 3rem;
    border: 1px solid;
    padding: 1rem 1.4rem;
    opacity: 0.7;
    transition: all .3s
}

.owl-theme .owl-nav [class*='owl-']:hover {
    opacity: 1;
    background-color: rgba(51, 51, 51, 0.43137254901960786);
    transform: scale(1.1);
}

@media (max-width: 900px) {
    .banner-topo {
        margin-top: 0px;
    }

    .banner-topo__indicadores .owl-dot > span, .banner-topo__indicadores .owl-dot.active span {
        width: 12px !important;
        height: 12px !important;
    }

    .owl-theme .owl-nav {
        display: none;
    }
}


@media (max-width: 650px) {
    .banner-topo {
        margin-top: 55px;
    }
}
/*
.owl-theme .owl-nav [class*='owl-']:after {
    content: " < ";
    display: block;
    background-color: red;
    height: 10rem;
    width: 10rem;
    font-size: 1rem;
    color: #fff;

    border-radius: 100%;
    padding-top: 4.1rem;
}
*/


.owl-theme .owl-nav .owl-prev {
    left: 3rem;

}


.owl-theme .owl-nav .owl-next {
    right: 3rem;
}

.owl-theme .owl-nav .owl-next .fa {
    margin-left: 6px;
}

.owl-theme .owl-nav .owl-prev .fa {
    margin-right: 6px;
}


.banner-topo__conteudo-container {
    z-index: 10;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: rgba(0, 0, 0, 0.61);
    padding: 20px;
}

.banner-topo__conteudo-container--unidade {
    top: 0;
    left: 0;
    transform: none;
    transform: translateY(50%);
}

/* Faz com que a imagem do vĆ­deo fique fullscreen  */
.banner-topo__item .owl-video-tn {
    background-size: cover;
}
.owl-carousel .banner-topo__item .owl-video-play-icon
{
    bottom: 20px;
    top: inherit;
}

.banner-topo__headline {
    text-align: center;
    font-size: 32px;
    line-height: 36px;
    color: #fff;
}

hr.meio {
    height: 3px;
    background-color: #fff;
    max-width: 50%;
}


.banner-topo__headline > b {
    font-size: 36px;
    line-height: 40px;
    color: #fff;
}


.banner-topo__conteudo-container--unidade .banner-topo__headline > b {
    font-size: 48px;
    line-height: 60px;

    display: inline-block;
    color: #fff;
}

.banner-topo__conteudo-container--unidade .banner-topo__botoes-container {
    margin-top: 20px;
}

.simulacao-form-container form {
    display: none;
}

.simulacao-form-container form:first-child {
    display: block;
}

/* Páginas */

.risco {
    position: relative;
}

.risco::after {
    content: " ";
    position: absolute;
    bottom: 10px;
    left: 0;
    height: 2px;
    display: block;
    width: 100%;
    background-color: #cc9933;
    z-index: 1;
}

.risco--topo::after {
    top: 10px;
}

.risco--lateral--esquerdo::after {
    top: 0px;
    left: 0;
    height: 100%;
    width: 2px;
    background-color: #fff;
}


.padding-secao {
    padding: 30px 0;
}

.secao-titulo {
    background-color: #333;
    text-align: center;
    padding: 10px 0;
    color: #fff;
}

.secao-titulo h2 {
    font-size: 36px;
    font-weight: normal;

}


.secao-titulo--esquerda h2 {
    text-align: left;
}

.secao--depoimentos {
    background-image: url(../images/bgDepoimentos.jpg);
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;	
}

.secao--noticias {
    background-image: url(../images/bgMeio.jpg);
    background-attachment: fixed;
    background-position:  center;
    background-size: cover;
    background-repeat: no-repeat;
}

.tv__item {
    background-color: #888;
    padding: 20px;
    width: 100%;
    margin-bottom: 30px;
}

.lista-noticias {
    list-style: none;
}

.lista-noticias__item {
    width: 100%;
    display: inline-block;
    margin-bottom: 16px;
}

.lista-noticias__link {
    border: 3px solid #810a10;
    list-style: none;
    padding: 8px;
    display: block;
    position: relative;
}

.lista-noticias__link__titulo {
    background-color: #999999;
    color: #fff;
    display: inline-block;
    width: 100%;
    position: relative;
    padding: 14px;
}

.circulo-mais {
    border-radius: 100%;
    background-color: #810a10;
    color: #fff;
    font-size: 16px; 
    display: inline-block;
    padding: 11px 18px;

}

.lista-noticias__link__titulo > .circulo-mais{
    position: absolute;
    right: 10px;
    bottom: 3px;

}

.noticia-bloco {
    background-color: #ccc;
    width: 100%;
    height: 500px;
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;
    background-size: cover;
}



.noticia-bloco--pequeno {
    height: 235px;
}

@media only screen and (max-width: 768px) {
    .noticia-bloco {
        height: 235px;
    }
}

.noticia-bloco__titulo {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #024478;
    padding: 10px 20px;
    color: #fff;
    min-height: 20px;
    text-transform: uppercase;

    -webkit-transition: all .4s ease-out;
    -moz-transition: all .4s ease-out;
    -o-transition: all .4s ease-out;
    transition: all .4s ease-out;  
}

.noticia-bloco__titulo:hover {
    color: #fff;
}

.noticia-bloco:hover > .noticia-bloco__titulo {
    opacity: 0.75;
    display: block;
    padding-top: 7%;
    padding-bottom: 7%;
}
.noticia-bloco--pequeno .noticia-bloco__titulo{
    font-size: 0.8em;
    line-height: 1.2em;
}

.noticia-bloco__titulo--laranja {
    background-color: #ff6634
}


.noticia-bloco__titulo--rosa {
    background-color: #c40a49;
}

.btn-home-noticias{
    border-radius: 20px 0 20px;
    border: solid 1px #024478;
    background-color: #024478;
    color: #fff !important;
}
.btn-home-noticias:hover{
    border-colo: #fff;
}

.secao--newsletter {
    color: #fff;
    margin-bottom: 0;
}

.news__form {

}
.news__form__cabecalho { 
}

.news__form__titulo {
    font-size: 22px;
    line-height: 33px;
}

.news__form__subtitulo {
    float: right;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
}

.news__form__input {
    background-color: #fff;
    padding: 2px;
    margin-top: 10px;
}

.news__form__input input {
    width: 100%;
    border:none;
    color: #333;
    padding: 6px 10px;
    outline: none;
}

.news__form__input button {
    float: right;
}

.news__telefone {
    text-align: right;
    font-size: 14px;
    line-height: 26px;
    font-weight: bold;
}

.news__telefone a {
    color: #fff;
    text-decoration: none;
}

.news__telefone__destaque {
    font-size: 26px;
    line-height: 30px;
}

.secao--contato {
    background-color: #fff;
}

.contato__mapa {
    padding-top: 50%;
    width: 100%;
}

.gmaps-container {
    margin-left: -15px;
    padding-right: 0;
}



@media only screen and (min-width: 768px) {
    #formContatoMapa {
        float: right;
    }

    #contato-secao #formContatoMapa {
        float: none;
    }

    .gmaps-container {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
    }  

    .contato__mapa {
        padding-top: none;
        height: 100%;
    }

}

.secao--contato-faixa {

}

.mapa-container {
    width: 50%;
    height: 600px;
    float: left;
    position: relative;
}

.mapa-container__mapa {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.mapa-container__icone {
    display: inline-block;
    font-size: 56px;
    margin-right: 10px;
}

.mapa-container__endereco > span {
    display: inline-block;
}


.mapa-container__endereco {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    background-color: #233e69;
    z-index: 100;

    padding: 10px 20px;



}

@media only screen and (max-width: 600px) {
    .mapa-container__endereco > span {
        float: left;
    }

    .mapa-container__endereco-texto {
        max-width: 80%;
        font-size: 2rem;
    }

}

@media only screen and (max-width: 997px) {
    .mapa-container {
        width: 100%;
    }
}

/* DEPOIMENTOS */
.depoimento-item {

}


.depoimento-item-nota {
    background-color: rgba(0, 0, 0, 0.58);
    display: block;
    max-width: 165px;
    padding-left: 8px;
    padding-top: 4px;
    margin: 10px 0;
}

.depoimento-item-imagem {
    height: 150px;
    width:150px;
    border-radius: 200px;
    background-size:cover;
    margin:0 auto;
    display: block;

}


.depoimento-item-conteudo {
    color:#333;
    padding-left: 10px;
}

.depoimento-item-nota-estrela {
    background:url(../images/estrela.png);
    display: inline-block;;
    background-size: contain;
    height: 18px;
    width: 18px;
    margin:2px 4px;
}



.depoimento-lista-item {
    background-color: #e6e6e6;
    border-radius: 200px;
}

.depoimento-lista-item .depoimento-item-nome {

    font-size: 18px;
    font-weight: bold;
}

.depoimento-lista-item .depoimento-item-depo {
    margin-top: 4px;
}


.depoimento-lista-item .depoimento-item-imagem {
    margin-left: 12px;
}

@media only screen and (max-width: 640px) {
    .depoimento-item-conteudo  {
        text-align: center;
    }
    .depoimento-item-nota {
        margin-left: auto;
        margin-right: auto;
    }
}

/* FIM DEPOIMENTOS */

/* COMPONENTES */


/* COMPONENTE Carregando */
.spinner {

    margin-top: 50%;
    margin-left: 50%;
    width: 40px;
    height: 40px;
    background-color: #233e69;
    background-image: url(../images/logo-loading.png);
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 auto;
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;


}

.spinner--medium {
    width: 80px;
    height: 80px;

}

.loading-cover {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: rgba(255,255, 255, 0.5);
}

.spinner-position {
    position: absolute;
    top: 50% !important;
    transform: translateY(-50%);
}


@-webkit-keyframes sk-rotateplane {
    0% { -webkit-transform: perspective(120px) }
    50% { -webkit-transform: perspective(120px) rotateY(180deg) }
    100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
}

@keyframes sk-rotateplane {
    0% { 
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg) 
    } 50% { 
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg) 
    } 100% { 
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

/* COMPONENTS - CARTAO */

.cartao {
    padding: 20px;
}

.cartao__separador {
    width: 90%;
    margin: 30px auto;
    height: 2px;
    background-color: #888;
}

.cartao__titulo {
    font-size: 26px;
    line-height: 38px;
    color: #931518;
    margin-top: 0;
    margin-bottom: 24px;
}

.cartao__secao {
    background-color: #ccc;
    padding: 20px;
    color: #333;
}

.unidade-item,
.servico-item {
    cursor: pointer;
}
/* COMPONENTES - BLOCO */
.bloco-item {
    margin-bottom: 28px;
    overflow: hidden;
    position: relative;
    display: block;


    -webkit-transition: all .4s ease-out;
    -moz-transition: all .4s ease-out;
    -o-transition: all .4s ease-out;
    transition: all .4s ease-out;    	
}

.bloco-item:hover {
    -webkit-box-shadow: inset 0px 0px 0px 10px rgba(255,255,255,1);
    -moz-box-shadow: inset 0px 0px 0px 10px rgba(255,255,255,1);
    box-shadow: inset 0px 0px 0px 10px rgba(255,255,255,1);
}

.bloco-item--vermelho:hover {
    background-color: #9d1b21;
}

.bloco-item__imagem {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    background-size: cover;
    background-position: center;

    -webkit-transition: all .4s ease-out;
    -moz-transition: all .4s ease-out;
    -o-transition: all .4s ease-out;
    transition: all .4s ease-out;    	


}


.bloco-item:hover .bloco-item__imagem  {
    -moz-transform: scale(1.2);  
    -webkit-transform: scale(1.2);  
    -o-transform: scale(1.2);  
    -ms-transform: scale(1.2);  

    opacity: 0.7;
}

.bloco-item__imagem::after {
    content: " ";
    position: absolute;;
    width: 100%;
    height: 100%;
    display: block;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.25+100 */
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.50) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.50) 100%); /* Chrome10-50,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.50) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#40000000',GradientType=0 ); /* IE6-9 */


}
.bloco-item__titulo {
    display: block;
    background-color: rgba(220, 34, 34, 0.68);
    color: #fff;
    font-size: 28px;
    font-weight: bold;
    padding: 10px 0;
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    text-align: center;

    -webkit-transition: all .4s ease-out;
    -moz-transition: all .4s ease-out;
    -o-transition: all .4s ease-out;
    transition: all .4s ease-out;    	
}

.bloco-item__link {

}

/* COMPONENTES - CIRCULO */
.circulo-item {
    overflow: hidden;
    border-radius: 100%;

    border: 10px solid rgba(255, 255, 255, 0.49);
    -webkit-transition: all .05s ease-out;
    -moz-transition: all .05s ease-out;
    -o-transition: all .05s ease-out;
    transition: all .05s ease-out;   	
}

.circulo-item:hover {

    -webkit-box-shadow: 0px 5px 30px 0px rgba(50, 50, 50, 0.77);
    -moz-box-shadow:    0px 5px 30px 0px rgba(50, 50, 50, 0.77);
    box-shadow:         0px 5px 30px 0px rgba(50, 50, 50, 0.77);



}

.circulo-item__imagem {
    background-size: cover;
    background-position: center;
    position: relative;


    -webkit-transition: all .4s ease-out;
    -moz-transition: all .4s ease-out;
    -o-transition: all .4s ease-out;
    transition: all .4s ease-out;  
}

.circulo-item__sombra {
    position: relative;
    display: block;
    width: 100%;
    padding: 7%;
    border-radius: 100%;
    margin-top: -10%;
    background-color: transparent;

    -webkit-box-shadow: 0px 5px 30px 0px rgba(50, 50, 50, 0.77);
    -moz-box-shadow:    0px 5px 30px 0px rgba(50, 50, 50, 0.77);
    box-shadow:         0px 5px 30px 0px rgba(50, 50, 50, 0.77);

    -webkit-transition: all .4s ease-out;
    -moz-transition: all .4s ease-out;
    -o-transition: all .4s ease-out;
    transition: all .4s ease-out;  
}


.circulo-item:hover .circulo-item__imagem {
    -moz-transform: scale(1.2);  
    -webkit-transform: scale(1.2);  
    -o-transform: scale(1.2);  
    -ms-transform: scale(1.2);  

    opacity: .8;

}

.circulo-item__titulo {
    margin-top: 10px;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

/* GALERIA */
.galeria {

}

.galeria-clube {
    padding-top: 2rem;
    padding-bottom: 3rem;
    background-image: url('../images/bgGalera.jpg');
    background-size: cover;
    background-position: center;
}

.galeria__imagem-container {
    overflow: hidden;
    position: relative;
}

.galeria__imagem {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    background-image: url('../images/unidade.jpg');
    background-position: center;
    background-size: cover;

    -webkit-transition: all .4s ease-out;
    -moz-transition: all .4s ease-out;
    -o-transition: all .4s ease-out;
    transition: all .4s ease-out;  	
}

.galeria__imagem:hover {
    -moz-transform: scale(1.2);  
    -webkit-transform: scale(1.2);  
    -o-transform: scale(1.2);  
    -ms-transform: scale(1.2);  

    opacity: 0.7;

}



.botao--transparente-vermelho {
    background-color: #931518;
    color: #fff;

    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";

    /* IE 5-7 */
    filter: alpha(opacity=50);

    /* Netscape */
    -moz-opacity: 0.5;

    /* Safari 1.x */
    -khtml-opacity: 0.5;

    /* Good browsers */
    opacity: 0.5;	
}


.botao--transparente-vermelho:hover {
    background-color: #931518;
    color: #fff;	


    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";

    /* IE 5-7 */
    filter: alpha(opacity=100);

    /* Netscape */
    -moz-opacity: 1;

    /* Safari 1.x */
    -khtml-opacity: 1;

    /* Good browsers */
    opacity: 1;	
}

.botao-footer-fixo {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 120;
}


/* Ratio */
.box-4-3 {
    width: 100%;
    padding-top: 75%; /* 4:3 Aspect Ratio */
    display: block;
}

.box-1-1 {
    width: 100%;
    padding-top: 75%; /* 4:3 Aspect Ratio */
    display: block;
}



.circle-1-1 {
    width: 100%;
    padding-top: 100%; /* 1:1; Aspect Ratio */
    display: block;
    background-color: #333;
    border-radius: 100%;
}


/* COMPONENTES - LISTA COLUNAS */

.lista-colunas {
    overflow:hidden;
}

.lista-colunas__item {
    line-height:1.5em;
    float:left;
    display:inline;
    width: 100%;
    margin: 12px 0;
}

.lista-colunas--2 li  { width:50%;} 
.lista-colunas--3 li  { width:33.333%; }
.lista-colunas--4 li    { width:25%; } 
.lista-colunas--6 li     { width:16.666%; }

/* TODO: Fazer Responsivo como 100% */



/* HACKS */
.img-fluid-responsive {
    display: block;
    width: 100%;
    height: auto;
}

.align-center-middle {
    margin-left: 50%;
    margin-top: 100%;

    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

/**  YOUTUBE VIDEO  **/
.video-background ,
.video-background * { box-sizing: border-box; }

.video-background {
    background: #000;
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -99;
}
.video-foreground,
.video-background iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}
#vidtop-content {
    top: 0;
    color: #fff;
}
.vid-info { position: absolute; top: 0; right: 0; width: 33%; background: rgba(0,0,0,0.3); color: #fff; padding: 1rem; font-family: Avenir, Helvetica, sans-serif; }
.vid-info h1 { font-size: 2rem; font-weight: 700; margin-top: 0; line-height: 1.2; }
.vid-info a { display: block; color: #fff; text-decoration: none; background: rgba(0,0,0,0.5); transition: .6s background; border-bottom: none; margin: 1rem auto; text-align: center; }
@media (min-aspect-ratio: 16/9) {
    .video-foreground { height: 300%; top: -100%; }
}
@media (max-aspect-ratio: 16/9) {
    .video-foreground { width: 300%; left: -100%; }
}
@media all and (max-width: 600px) {
    .vid-info { width: 50%; padding: .5rem; }
    .vid-info h1 { margin-bottom: .2rem; }
}
@media all and (max-width: 500px) {
    .vid-info .acronym { display: none; }
}

.btn-voltar{
    border-radius: 20px 0 20px;
}

.inline{
    display: inline-block;
    float: none;
}

.margin-l15{margin-left: 15px}

@media (max-width: 767px) {
    .news__form__subtitulo {float: none;}
    .risco::after {display: none !important;}
    .text-center-sm {text-align: center !important;}
}