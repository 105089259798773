.col-result .item {
    width: 100%;
    position: relative;
    background: #5c5c5d;
    height: 40px;
    border-radius: 20px;
    margin-bottom: 8px;
}

.col-result .item .numero {
    float: left;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    background: #162532;
    line-height: 40px;
    font-weight: bold;
    font-size: 20px;
    position: relative;
    color: rgba(255, 255, 255, 0.5);
}

.col-result .item .texto {
    margin-left: 20px;
    display: inline-block;
    line-height: 40px;
    color: #010101;
    font-size: 20px;
    opacity: 0.8
}

.col-result .item span {
    display: none
}

.col-result .item.selected {
    cursor: pointer;
    border-right: solid 2px #fff;
}

.col-result .item.selected span {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    color: #091016;
    display: inline-block
}

.col-result .item.selected .numero {
    background: #c75619;
    color: #fff;
}

.col-result .item.selected .texto {
    opacity: 1;
}

.col-result .dados.select {
    position: relative;
    display: inline-block;
    padding: 15px 20px;
    width: 100%
}

.col-result .dados img {
    height: 100px;
    width: 120px;
    object-fit: cover;
    float: left
}

.col-result .dados .col-descricao {
    float: right;
    width: calc(100% - 150px);
    color: #fff
}

.col-result .dados h3 {
    font-size: 18px;
    margin: 5px 0;
    color: #fff;
}

.lista-agenda .reservar:hover a:before {
    content: 'Reservar';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    color: #fff;
    padding: 5px 15px;
    background: #c75619
}

.inline-block {
    display: inline-block;
    transform: translate
}

@media (max-width: 550px) {
    .list-spot .palco {
        overflow: hidden;
        padding: 0px;
    }
    .list-spot .palco>div {
        margin-left: -80px;
    }
    .list-spot .palco #PALCO text {
        display: none;
    }
}