header {
    background: rgba(26, 26, 26, 0.8509803921568627);
    position: absolute;
    right: 0;
    top: 1rem;
    padding: 0;
    width: 100%;
    z-index: 500;
    box-shadow: 1px 0px 8px #333;
}

header.header--menor{
    position: fixed;
    top: 0;
}

header .container {
    position: relative;
}

header .col-logo {
    margin: 40px 0 0;
    max-width: 200px;
    padding-right: 0;
    top: -40px;
    left: 0;
    position: absolute;
}

header.header--menor .col-logo .l1{
    display: none
}

header.header--menor .col-logo .l2 {
    display: inline !important;
    visibility: visible !important;
    height: 50px;
    margin-top: 5px;
}

header .header-md {
    max-width: calc(100% - 380px);
}

header .line-1 {height: 35px;line-height: 35px;overflow: hidden}
header .line-1 .sp {font-size: 10px;margin: 0 4px 0 2px}

header .hed-fone > span {display: inline-block;font-size: 20px;margin-right: 25px}
header .hed-fone i {font-size: 16px}

header .hed-rede i {font-size: 18px;margin: 0 12px}

header .hed-compras > a {margin-left: 25px}
header .hed-compras i {font-size: 18px;}

header .line-2 {line-height: 30px;margin: 14px 0;}
header .line-2 nav, header .header-top .line-2 ul {margin: 0;text-align: right;width: 100%;}
header .line-2 li {margin: 0;padding: 0 1.5% !important;position: relative;color: #ffffff}
header .line-2 li a {color: #fff;font-size: 16px; font-weight: bold;}
header .line-2 li:nth-child(1n+2) { border-left: 1px solid #fff; }
header .line-2 li i {font-size: 30px;} 
header .line-2 li:hover .dropdown-menu{display: block;} 

header .col-fone {position: absolute;top: 5px;right: 15px;font-weight: 700;line-height: 2rem;}
header .col-fone .info{font-size: 1.6rem}
header .col-fone .text{font-size: 2.5rem}
header .col-fone .text span{font-size: 1.7rem}

header .header-bottom .icon-cart.vs .label {background: #deba79;left: 20px;}

@media (max-width: 1170px) {
}

@media (max-width: 1030px) {

}

@media (max-width: 991px) {
    header {
        height: 4rem;
        top: 0
    } 
    header .col-logo {
        max-width: 100%;
        text-align: center;
    }

    header .col-logo > a {
        display: inline-table;
        position: relative;
        z-index: 1;
    }

    header .col-logo > a img {
        max-height: 135px;
    }

    header .header-md {
        left: 0;
        max-width: 100%;
        position: absolute;
        top: 0;
    }

    header .line-2 {
        background: transparent;
        margin-top: 0;
        overflow: inherit;
        line-height: 80px
    }

    header .line-2 nav, header .header-top .line-2 ul {
        margin: 30px 0 0;
        text-align: right;
        width: 100%;
    }

    header .line-2 li {
        border: none;
        text-align: center;
        width: 25%;
    }

    header .line-2 li.inline {
        width: auto;
        max-width: 380px;
    }
    header .line-2 li figure {
        margin-top: -25px; 
        max-width: 40%;
    }

    header .line-2 li:nth-child(1n+2) { border-left: none; }

    header .line-2 .hamburguer {
        margin-top: -38px;
    }
}

@media (max-width: 650px) {
    header .line-2 li.inline {max-width: 100%}
    header .line-2 li.inline img{
        max-width: 100px;
        /*margin-left: -20px
        margin-left: -26px;
        max-width: 100px;
        margin-top: -50px;*/

    }
    header .line-2 {line-height: 80px}
}

@media (max-width: 480px) {
    header .col-fone{display: none}

}

/* ############################ *//* ## | Menu mobile ########## *//* ############################ */
.offset-canvas-mobile{
    background-color: rgba(28, 51, 72, 0.94);
    bottom:0;left:0;
    padding:40px;position:fixed;right:0;
    top:0;transform:translateX(-100%);-moz-transform:translateX(-100%);
    -ms-transform:translateX(-100%);-o-transform:translateX(-100%);
    -webkit-transform:translateX(-100%);transition:all 1s cubic-bezier(1,0,0,1);
    -o-transition:all 1s cubic-bezier(1,0,0,1);-webkit-transition:all 1s cubic-bezier(1,0,0,1);
    z-index:1200
}
.offset-canvas-mobile.open{transform:translateX(0);-moz-transform:translateX(0);-ms-transform:translateX(0);-o-transform:translateX(0);-webkit-transform:translateX(0);}
.offset-canvas-mobile>div{height:100%;overflow:auto;padding-right:20px;width:100%}
.offset-canvas-mobile ul{list-style:none;margin-top:10px;padding:0}
.offset-canvas-mobile .mobile-nav li{text-align:center; font-size:2rem;border-bottom:1px solid #fff;padding:10px 0;position:relative}
.offset-canvas-mobile .mobile-nav li a{padding:6px 0;color:#fff;font-weight: bold;display:inline-block;text-transform:uppercase;width:calc(100% - 50px); line-height: 2.4rem;}
.offset-canvas-mobile .mobile-nav li a:active,.offset-canvas-mobile .mobile-nav li a:focus,.offset-canvas-mobile .mobile-nav li a:hover,.offset-canvas-mobile 
li a:hover{color:#ccc!important;text-decoration:none}
.offset-canvas-mobile .mobile-nav li ul{display:none;margin:0;padding-left:20px}
.offset-canvas-mobile .mobile-nav li ul li:last-child{border:none}
.offset-canvas-mobile .mobile-nav li ul a{font-size:90%}
.offset-canvas-mobile .mobile-nav li.has-submenu>span{font-size:20px;height:20px;padding:1px 10px 0}
.offset-canvas-mobile .mobile-addits li{display:inline-block;margin:0 5px}
.offset-canvas-mobile .mobile-addits li a{background-color:#fff;color:#000;display:inline-block;padding:10px}
.offset-canvas-mobile.open-canvas{transform:translateX(0);-moz-transform:translateX(0);-ms-transform:translateX(0);-o-transform:translateX(0);-webkit-transform:translateX(0)}
.offset-canvas-mobile .close-canvas{color:#fff;font-size:25px;position:absolute;right:20px;top:20px;z-index:5}
.offset-canvas-mobile .close-canvas:active,.offset-canvas-mobile .close-canvas:focus{text-decoration:none}
.offset-canvas-mobile .list-inline{text-align:center}
.offset-canvas-mobile .list-inline li{line-height:25px}
.offset-canvas-mobile .list-inline i{font-size:15px;margin-right:2px}

