body.preload{
    overflow: hidden;
    height: 0;
}

.load-page{
    position: fixed; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: none;
    background: #000;
    background-size: cover;
}

.preload .load-page{
    display: block;
    width: 100%;
}

.load-page .coll {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}

.load-page .coll::before {
	content: '';
	width: 250px;
	height: 150px;
	background: url(../../images/loadcc.svg) center center no-repeat;
	background-size: contain;
	display: inline-block;
}

.load-page .coll::after{
	content: 'carregando...';
	display: block;
	text-align: center;
        color: #fff;
}