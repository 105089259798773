.botao {
	transition: all 0.2s ease-out;
	color: #fff;
	display: inline-block;
	text-align: center;
	padding: 0.5rem 0;
}

.botao:hover {
	color: #fff;
}
.botao-circulo {
	border-radius: 40px;
}

.botao {
    padding: 8px 20px;
    box-sizing: border-box;
    border: none;
    background-color: #373631;
    color: #ccc;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    display: block;

    -webkit-transition: all .4s ease-out;
    -moz-transition: all .4s ease-out;
    -o-transition: all .4s ease-out;
    transition: all .4s ease-out;  

}

a.botao {
    text-decoration: none;
}

.botao:hover {
    background-color: #333;
    color: #ccc;	
}

.botao--fluido {
    width: 100%;
}

.botao--preto {
    background-color: #333;
    color: #ccc;	
}


.botao--preto:hover {
    background-color: #444;
    color: #fff;	
}

.botao--vermelho {
    background-color: #FF5722;
    color: #fff;	
    border: 3px solid transparent;
}


.botao--vermelho:hover {
    background-color: #fff;
    border: 3px solid #FF5722;
    color: #FF5722;	
}

.botao--azul {
    background-color: #233e69;
    color: #fff;	
    border: 3px solid transparent;
}


.botao--azul:hover {
    background-color: #fff;
    border: 3px solid #233e69;
    color: #233e69;	

}

.botao.botao--borda-azul {
    background-color: transparent;
    color: #fff;    
    border: 5px solid #009edb;
    display: inline-block;
}


.botao.botao--borda-azul:hover {
    background-color: transparent;
}

.botao--transparente-vermelho {
    background-color: #931518;
    color: #fff;

    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";

    /* IE 5-7 */
    filter: alpha(opacity=50);

    /* Netscape */
    -moz-opacity: 0.5;

    /* Safari 1.x */
    -khtml-opacity: 0.5;

    /* Good browsers */
    opacity: 0.5;	
}


.botao--transparente-vermelho:hover {
    background-color: #931518;
    color: #fff;	


    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";

    /* IE 5-7 */
    filter: alpha(opacity=100);

    /* Netscape */
    -moz-opacity: 1;

    /* Safari 1.x */
    -khtml-opacity: 1;

    /* Good browsers */
    opacity: 1;	
}

.botao-footer-fixo {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 120;
}

.botao.botao--arco-iris {
    display: inline-block;
    background-image: url(../images/borda-arco-iris.png);
    background-size: cover;
    background-position: center;
    padding: 1.5rem 2rem;    
    background-color: transparent;
    color: #fff;
    transition: 0.2s transform;
}

.botao.botao--arco-iris:hover {
     background-color: transparent;
     transform: scale(1.05);
} 