
.secao-filtros {
    padding: 18px 18px 0 18px;
    margin-bottom: 34px;
    background-color: rgba(0,0,0,.6);
}

.secao-filtros h2 {
    margin-top: 0;
    color: #fff;
}

.lista-contato--balada-segura > li {
	max-width: 33%;
	float: left;
	margin-right: 2rem;
}

.lista-horizontal__item.balada-segura__item {
    min-height: 230px;
}

@media(max-width: 767px) {
	.lista-contato--balada-segura > li {
		max-width: 100%;
	}
}