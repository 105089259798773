
.secao--newsletter {
    color: #fff;
    margin-bottom: 0;
    background-color: #182430;
}

.news__form {

}
.news__form__cabecalho { 
}

.news__form__titulo {
    font-size: 22px;
    line-height: 33px;
}

.news__form__subtitulo {
    float: right;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
}

.news__form__input {
    background-color: #fff;
    padding: 2px;
    margin-top: 10px;
}

.news__form__input input {
    width: 100%;
    border:none;
    color: #333;
    padding: 6px 10px;
    outline: none;
}

.news__form__input button {
    float: right;
}

.news__telefone {
    text-align: right;
    font-size: 14px;
    line-height: 26px;
    font-weight: bold;
}

.news__telefone a {
    color: #fff;
    text-decoration: none;
}

.news__telefone__destaque {
    font-size: 26px;
    line-height: 30px;
}

.news .inline {
    margin-left: 15px;
}

@media(max-width: 767px) {
    .news__form__subtitulo {
        text-align: center;
        float: none;
        font-size: 1.3rem;
        line-height: 2.6rem;
    }
}