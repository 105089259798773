.secao-sobre-nos {
    background-image: url(../images/bgCentro1.png);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: right 118px;
}

.secao-quem-somos-bolas {
    padding-top: 3rem;
	background-image: url('../images/bgJackson.jpg');
	background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
}

.quem-somos-item__titulo {
	position: absolute;
    bottom: 11rem;
    font-size: 2.5rem;
    font-weight: bold;
    color: #fff;
    text-align: center;
    left: 0;
    width: 100%;
    transition: all 0.2s ease-out;
}

.quem-somos-item  {
    text-align: center;
	position: relative;
	min-height: 26rem;
	margin-bottom: 2rem; 

}

.quem-somos-item:before  {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: " ";
    background-image: url('../images/bgIcons.png');
    opacity: 0.6;
    background-size: cover;
    background-position: center;   
    transition: .3s opacity;  
}

.quem-somos-item:hover:before  {
    opacity: 1;
}


.quem-somos-item > .botao  {
    position: absolute;
    display: inline-block;
    bottom: 2rem;
    font-size: 1.4rem;
    font-weight: bold;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.3s all;
    padding: 1rem 2rem;
}

.quem-somos-item > img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%,-40%);
    margin: 0 auto;
    transition: all 0.2s ease-out;
}

.quem-somos-item:hover > .quem-somos-item__titulo {
	transform: scale(1.2);
}

.quem-somos-item:hover > img {
	margin-top: -22px;
    transform: translate(-50%,-40%) scale(1.1);
}

.quem-somos-item:hover > .botao {
    transform: translateX(-50%) scale(1.1);
}


