.secao--contato {
    background-color: #fff;
    color: #4c4c4c;
}

.lista-contato {
    list-style: none;
}
.lista-contato li {
    vertical-align: text-bottom;
    line-height: 3rem;
}
.lista-contato li > .fa {
    font-size: 1.6rem;
    text-align: center;
    margin-right: 0.4rem;
    width: 1.7rem;
    display: inline-block;
}

.lista-contato--dark li,
.lista-contato--dark li > a,
.lista-contato--dark li > .fa {
    color: #fff;
}

.secao--contato .input-flat {
    border: 1px solid #ccc;
    margin-bottom: 1rem;
    box-shadow: none;
}

.contato__mapa {
    padding-top: 50%;
    width: 100%;
}

.gmaps-container {
    margin-left: -15px;
    padding-right: 0;
} 

.secao--contato__titulo {
    font-weight: bold;
    color: #777;
    font-size: 1.3rem;
    margin-bottom: 2.5rem;
}

@media only screen and (min-width: 768px) {
    #formContatoMapa {
        float: right;
    }

    #contato-secao #formContatoMapa {
        float: none;
    }

    .gmaps-container {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
    }  

    .contato__mapa {
        padding-top: none;
        height: 100%;
    }

}

.secao--contato-faixa {

}

.mapa-container {
    width: 50%;
    height: 600px;
    float: left;
    position: relative;
}

.mapa-container__mapa {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.mapa-container__icone {
    display: inline-block;
    font-size: 56px;
    margin-right: 10px;
}

.mapa-container__endereco > span {
    display: inline-block;
}


.mapa-container__endereco {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    background-color: #233e69;
    z-index: 100;

    padding: 10px 20px;



}

@media only screen and (max-width: 600px) {
    .mapa-container__endereco > span {
        float: left;
    }

    .mapa-container__endereco-texto {
        max-width: 80%;
        font-size: 2rem;
    }

}

@media only screen and (max-width: 997px) {
    .mapa-container {
        width: 100%;
    }
}