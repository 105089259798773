.outer-mapa {
	position: relative;
}

.titulo-mapa {
	position: absolute;
	left: 0;
	top: 0;
	display: block;
	width: 100%;
	background-color: rgba(0,0,0,0.67);
	z-index: 10;
} 

#mapa.contato__mapa {
	padding-top: 30%;
}


@media (max-width: 800px) {
	#mapa.contato__mapa {
		padding-top: 50%;
	}
}