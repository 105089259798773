.midia-social {
	list-style: none;
	text-align: center;
}
.midia-social li {
    display: inline-block;
}

.midia-social__item {
    display:inline-block;
    width: 40px;
    height: 40px;
    background-size: cover;
}

.midia-social__item.facebook {
    background-image: url('../images/iconFacebook.png');
}
 
.midia-social__item.instagram {
    background-image: url('../images/iconInstagram.png');
}

.midia-social__item.youtube {
    background-image: url('../images/iconYoutube.png');
}