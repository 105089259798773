html {
    font-size: 14px;
}

body {
    background-color: #091016;
}

body.corpo {
    background-color: #000e29;
    background-position: top;
    background-attachment: fixed;
    background-image: url('../images/bgCC.jpg');
    background-size: auto;
    background-repeat: no-repeat;    
}

a:hover {
    text-decoration: none;
}
.uppercase {
    text-transform: uppercase;
}
.inline {
    display: inline-block;
    float: none;
}


.input-flat {
    outline: none;
    border: none;
    padding: 8px 20px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 28px;
    -webkit-transition: all .4s ease-out;
    -moz-transition: all .4s ease-out;
    -o-transition: all .4s ease-out;
    transition: all .4s ease-out;   

    border: 2px solid transparent;
}

.input-flat:focus {
    border: 2px solid #909090;
}

.shadow{
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.55);
    -moz-box-shadow:    0px 2px 5px 0px rgba(0, 0, 0, 0.55);
    box-shadow:         0px 2px 5px 0px rgba(0, 0, 0, 0.55);
}


.borda-luminosa {
    border-width: 4px;
    animation: borda-luminosa 1.5s ease-in-out infinite;
}

.figura-container {
    width: 100%;
    padding-top: 100%;
    position: relative;
    margin-bottom: 2rem;
}

.figura {
    left: 0;
    top: 0;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
}
.figura > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: .3s all;
}

.lista-horizontal__item:hover .figura > img,
.figura:hover > img {
    transform: scale(1.3) rotate(20deg);
}

.figura__legenda {
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    width: 100%;
    padding: 1rem 2rem;
    color: #fff;
    text-align: center;
    transition: .3s all;
    height: 100%;
    padding-top: 50%;
    font-weight: bold;
    font-size: 1.4rem;
}

.figura:hover > .figura__legenda {
    max-height: 100%;
    background-color: rgba(10,10,10,0.8);
}

.pagina__titulo__container {
    margin-top: 8rem;
    margin-bottom: 4rem; 
    background-image: url('../images/sombra.png');
    background-position: bottom;
    padding-bottom: 1rem;
    text-align: center;
    color: #fff;
    background-repeat: no-repeat;

}


.pagina__subtitulo {

}

.lista-horizontal {

}
.lista-horizontal__imagem {
    padding-top: 100%;
    width: 100%;
}
.lista-horizontal__item {
    margin-bottom: 2rem;
    color: #fff;
    background-color: rgba(0,0,0,0.5);
    box-shadow: 0 6px 20px rgba(15, 23, 37, 0.6313725490196078);
    transition: all .3s;
    cursor: pointer;
}

.lista-horizontal__item:hover {
    background-color: rgba(0,0,0,.8);
}
.lista-horizontal__conteudo {
    padding: 1rem;
}

p.lista-horizontal__descricao {
    font-size: 1.5rem;
}

.lista-horizontal__titulo {
    font-size: 2rem;
    text-transform: uppercase;
}

.lista-horizontal__titulo > a {
    color: #fff;
} 

.lista-horizontal__subtitulo {
    font-size: 1.3rem;

}


@keyframes borda-luminosa {
    0% { 

    }
    50% { 
        -webkit-box-shadow: 0px 0px 10px 3px #9E9E9E;
        -moz-box-shadow: 0px 0px 10px 3px #9E9E9E;
        box-shadow: 0px 0px 10px 3px #9E9E9E;
    }
    100% { 

    }
}

.gradiente {
    background: linear-gradient(180deg,rgba(0,0,0,.06) 0,transparent);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0f000000",endColorstr="#00000000",GradientType=0);	
}



.risco {
    position: relative;
}

.risco::after {
    content: " ";
    position: absolute;
    bottom: 10px;
    left: 0;
    height: 2px;
    display: block;
    width: 100%;
    background-color: #cc9933;
    z-index: 1;
}

.risco--topo::after {
    top: 10px;
}

.risco--lateral--esquerdo::after {
    top: 0px;
    left: 0;
    height: 100%;
    width: 2px;
    background-color: #fff;
}

.section-padding {
    padding: 3rem 0;

}

.secao-cinza {
    background-color: #ccc;
}

.fundo--preto {
    background-color: #333;
}

.section__titulo {
    color: #fff;
    text-align: left;
    margin-bottom: 2rem;
}

.container{
    width: 95%;
    max-width: 1200px;
}

.modal-backdrop.in {
    z-index: 1000;
    position: fixed;
}

.modal-dialog,
.modal-content {
    border-radius: 0;
}


.navSliderContainer span {
    border-radius: 100%;
    text-align: center;
    color: #F91A55;
    padding: .6rem 1rem;
    cursor: pointer;
    transition: color .3s;
}

.navSliderContainer span:hover {
    color: #c91a46;
}
@media( max-width: 767px ) {
    .section__titulo {
        text-align: center;
    }

    .pagina__titulo {
        font-size: 24px;
    }

    .pagina__subtitulo {
        font-size: 16px;
    }

    .pagina__titulo__container {
        margin-top: 6rem;
    }

}
@media( max-width: 650px ) {
    .navSliderContainer {
        display: block;
        width: 100%;
        text-align: center;
        padding: 1rem 0;
    }

    .section__titulo {
        font-size: 22px;
    }

    .show__titulo {
        font-size: 1.5rem;
        padding: 0 25px;
    }
}

.addthis_inline_share_toolbox{
    display: none;
}

.addthis_inline_share_toolbox:hover, .open .addthis_inline_share_toolbox{
    display: inline-block;
}