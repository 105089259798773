.produto__item {
	padding-top: 70%;
	width: 100%;
	margin-bottom: 2rem;
	background-size: 100%;
	background-position: center;
	cursor: pointer;
	transition: all 0.3s ease-out; 
	position: relative;
        background-size: cover
    
}

.produto__item:before {
	content: " ";
	position: absolute;
	height: 93%;
	width: 93%;
	border: 2px solid #fff;
	left: 50%;	
	top: 50%;
	transform: translate(-50%,-50%);	
	z-index: 2;
		
}

.produto__item:after {
	content: " ";
	/*background-color: rgba(0,0,0,0.8);*/
        background: linear-gradient(to top,rgba(0, 0, 0, 0.8), rgba(53, 94, 130, 0.5));
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	border: 2px solid #fff;
	padding: 1rem;
}
.produto__item__titulo {
    z-index: 1;
    color: #fff;
    font-size: 2rem;
    text-align: center;
    position: absolute;
    top: 40%;
    transform: translate(-50%);
    margin: 0 auto;
    display: block;
    left: 50%;
    width: 100%;
    padding: 0 10%;
}


.produto__item:hover { 
	background-size: 120%;
}
